import { Injectable } from '@angular/core';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { DataRangeService } from './data-range.service';
import { ErrorHandlingService } from './error-handling.service';
import { LoadingService } from './loading.service';
import { WebSocketService } from './web-socket.service';

@Injectable()
export class SearchService {
  constructor(
    private webSocketService: WebSocketService,
    private loadingService: LoadingService,
    private dataService: DataRangeService,
    private errorHandlingService: ErrorHandlingService,
  ) {}

  search(params: {
    workstation?: string;
    file?: string;
    carline?: string;
    ordertype?: string;
    orderident?: string;
    originalFilename?: string;
    startDate?: string;
    endDate?: string;
    selection?: string;
    page?: number;
    pageSize?: number;
  }) {
    const message = {
      action: 'listMetadata',
      params: params,
    };

    if (params.startDate && params.endDate) {
      this.dataService.setDates(params.startDate, params.endDate);
    }

    // Set loading to true when the job is started
    this.loadingService.isLoadingSubject$.next(true);

    // Send the message to start the job
    this.webSocketService.sendMessage(message);

    // Capture and track jobId when the 'started' message is received
    return this.webSocketService.getMessages().pipe(
      // Filter only messages that signal the job has started
      filter((msg) => msg.status === 'started' && msg.jobId),
      tap((msg) => {
        console.log('Job started with ID:', msg.jobId);
      }),

      // Switch to listen for the 'completed' message with the same jobId
      switchMap((msg) => {
        const jobId = msg.jobId;

        // Listen for messages specifically related to this jobId and that are completed
        return this.webSocketService.getMessages().pipe(
          filter((msg) => msg.status === 'completed' && msg.jobId === jobId),
          map((msg) => {
            return msg.metadata; // Return the metadata
          }),

          // Ensure loading indicator is turned off after job completion or error
          finalize(() => {
            this.loadingService.isLoadingSubject$.next(false); // Stop loading after job completion
          }),

          // Handle errors and stop the loading spinner in case of any error
          catchError((error) => {
            this.errorHandlingService.handleError(
              '500',
              'Error from Server',
              error,
            );
            this.loadingService.isLoadingSubject$.next(false); // Stop loading on error
            return throwError(error); // Re-throw the error to propagate
          }),
        );
      }),
    );
  }
}
