/* eslint-disable prettier/prettier */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Observable, take } from 'rxjs';
import { SearchResult } from 'src/app/search-result';
import { CheckboxService } from 'src/app/services/checkbox-service.service';
import { ColumnWidthService } from 'src/app/services/columnwidth.service';
import { DataPaginatorService } from 'src/app/services/data-paginator.service';
import { DataRangeService } from 'src/app/services/data-range.service';
import { DisplayedFilesService } from 'src/app/services/displayed-files.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { ExportSearchService } from 'src/app/services/export-search.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SearchService } from 'src/app/services/search-service.service';
import { SharedSearchService } from 'src/app/services/shared-search-service.service';
import { AlertInfoService } from '../../alert-info/service/alert-info.service';
import { AlertTypeEnum } from '../../alert-info/type/AlertType.enum';
import { ProductSearchModel } from '../picture-search/picture-search.component';

export interface ProductList {
  workstation: string;
  originalfilename: string;
  filename: string;
  carline: string;
  ordertype: string;
  orderident: string;
}

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatCheckboxModule,
    CdkDrag,
    MatCardModule,
    MatPaginatorModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatProgressSpinnerModule,
    NgxDatatableModule,
    FormsModule,
  ],
})
export class CheckboxListComponent {
  @ViewChild(MatTable, { read: ElementRef }) private matTableRef!: ElementRef;
  [x: string]: any;

  @Input()
  searchParams: ProductSearchModel = {};
  searchResults: SearchResult = { data: [], total: 0 };
  pagedSearchResults!: MatTableDataSource<ProductList>;
  pageSizeOptions: number[] = [25, 50, 75, 100];
  isLoadingPage = false;
  connectionError: boolean = false;
  selectAll = false;
  tooltipMessage: string = '';
  private selectedRows: ProductList[] = [];
  row: any;
  allowedFileExtensions: string[] = ['png', 'jpg', 'jpeg', 'bmp'];
  selection = new SelectionModel<ProductList>(true, []);
  
  

  constructor(
    private alerInfoService: AlertInfoService,
    private exportSearchService: ExportSearchService,
    private dataService: DataRangeService,
    private searchService: SearchService,
    private sharedSearchService: SharedSearchService,
    public checkboxService: CheckboxService,
    public dataPaginatorService: DataPaginatorService,
    public loadingService: LoadingService,
    private dialog: MatDialog,
    private columnWidthService: ColumnWidthService,
    private displayedFilesService: DisplayedFilesService,
    private errorHandlingService: ErrorHandlingService,


  ) {
    this.pagedSearchResults = new MatTableDataSource<ProductList>([]);

  }

  @Input()
  set searchResultsData(data: SearchResult) {
    this.loadingService.isLoadingSubject$.next(true);
    this.searchResults = data
    this.updatePagedResults();
    this.checkButtonDisabled();
    this.selectedRows = this.checkboxService.getSelectedRows();
    this.loadingService.isLoadingSubject$.next(false);
    
  }

  async updateResultsData(event: PageEvent): Promise<void> {
    await this.onPageChange((event = event));
    await this.wait(5000);
    this.loadingService.isLoadingSubject$.next(false);
    
  }

  ngOnInit(): void {
    // Subscribe to searchResults to get notified of changes
    this.checkboxService.getsearchResults().subscribe(results => {
      if (results) {
        // Do something with the new results
        this.updatePagedResults(results.page, results.pageSize);
        
      }
    });
  }


  showAlert(type: AlertTypeEnum, text: string) {
    this.alerInfoService.setAlert({
      type,
      text,
    });
  }

  get isLoading$(): Observable<boolean> {
    return this.loadingService.isLoadingSubject$.asObservable();
  }

  displayedColumns: string[] = [
    'select',
    'file',
    'originalFilename',
    'workstation',
    'carline',
    'orderType',
    'orderIdent',
    'creationDate',
    'preview',
  ];

  columns: any[] = [
    { field: 'file'},
    { field: 'originalfilename',},
    { field: 'workstation'},
    { field: 'carline'},
    { field: 'ordertype'},
    { field: 'orderident'},
    { field: 'creationdate'},
  ];

  isCenteredColumn(field: string): boolean {
    return ['workstation', 'carline', 'ordertype'].includes(field);
  }

  // Define an object to store column widths
columnWidths: { [key: string]: number } = {};

// Function to get the width for a column
getColumnWidth(field: string): number {
  return this.columnWidthService.getColumnWidth(field) // Default width is 50 if not specified
} 

onColumnResize(event: any): void {
  const columnField = event.column.prop;
  const newWidth = event.newValue;
  this.columnWidthService.setColumnWidth(columnField, newWidth);

  const allColumnWidths = this.columnWidthService.getAllColumnWidths();
}


// Function to set the width for a column
setColumnWidth(field: string, width: number): void {
  this.columnWidths[field] = width;
}


checkButtonDisabled() {
  this.checkboxService.setButtonDisabled(
      this.checkboxService.getSelectedRows().length === 0 ||
      this.checkboxService.getSelectedRows().length > 200)
  }

  // In your component class
  isRowSelected(row: ProductList): boolean {
    return this.checkboxService.isRowSelected(row);
    
  }


  async onPageChange(event: PageEvent): Promise<void> {
    // Update the search parameters in the service
    // (Assuming these methods are synchronous)
    this.sharedSearchService.setSearchParamsPage(event.pageIndex);
    this.sharedSearchService.setSearchParamsPageSize(event.pageSize);
    this.dataPaginatorService.setPaginatorParams(event.pageIndex, event.pageSize);

    // Get the updated search parameters
    const searchParamsShared = this.sharedSearchService.getSearchParams();
    this.selectAll = false;

    this.loadingService.isLoadingSubject$.next(true);

    this.searchService.search(searchParamsShared)
    .pipe(take(1))
    .subscribe(
      async (result: any) => {

        // Ensure the response is not empty and contains valid data
        if (result && result.body) {
          // Instead of assigning directly, use the service to set searchResults
          this.checkboxService.setSearchResults(JSON.parse(result.body) as SearchResult);
          this.searchResults = (JSON.parse(result.body) as SearchResult)
          this.updatePagedResults(
            searchParamsShared.page,
            searchParamsShared.pageSize,
          )
          const test = this.checkboxService.getsearchResults();

        } else {
          throw new Error('Empty or invalid response received');
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loadingService.isLoadingSubject$.next(false);
      }
    );
}

private updatePagedResults(
    pageIndex = this.dataPaginatorService.getparamIndex(),
    pageSize = this.dataPaginatorService.getparamSize(),
  ) {
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;

    if (this.searchResults && this.searchResults.data) {

      this.pagedSearchResults.data = this.searchResults.data;
    } else {
      this.pagedSearchResults.data = [];
    }
  }

  selectRow(row: any, event: any) {

    // Handle the selected row here
    // Check if the checkbox is checked or unchecked
    const isChecked = event.target.checked;

    // Get the current selected rows from the service
    this.selectedRows = this.checkboxService.getSelectedRows();
    

    // Check if the row is already selected
    const index = this.selectedRows.findIndex((selectedRow) => {
      // Compare the properties that uniquely identify a row, e.g., workstation and orderident
      return selectedRow.filename === row.filename;
    });
    if (isChecked && index === -1) {
      // If the row is checked and not already selected, add it to the selected rows
      this.selectedRows.push(row);
    } else if (!isChecked && index !== -1) {
      // If the row is unchecked and already selected, remove it from the selected rows
      this.selectedRows.splice(index, 1);
    }
    this.checkButtonDisabled();
    this.getTooltip();
  }

  wait(milliseconds: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, milliseconds);
    });
  }

  selectAllRows() {
    if (this.selectAll) {
      // Iterate through pagedSearchResults.data and add rows to selectedRows if not already present
      this.selectedRows = this.checkboxService.getSelectedRows();
      this.pagedSearchResults.data.forEach((row) => {
        if (
          !this.selectedRows.some(
            (selectedRow) => selectedRow.filename === row.filename,
          )
        ) {
          this.selectedRows.push(row);
          this.selection.select(row);
        }
      });
    } else {
      // Iterate through pagedSearchResults.data and remove rows from selectedRows if present
      this.pagedSearchResults.data.forEach((row) => {
        const indexToRemove = this.selectedRows.findIndex(
          (selectedRow) => selectedRow.filename === row.filename,
        );
        if (indexToRemove !== -1) {
          this.selectedRows.splice(indexToRemove, 1);
          this.selection.deselect(row);
        }
      });
    }

    // Check if all rows on the current page are selected
    const allRowsOnPageSelected = this.pagedSearchResults.data.every((row) =>
      this.selectedRows.some(
        (selectedRow) => selectedRow.filename === row.filename,
      ),
    );


    // Update the selectAll flag based on the current page's selection status
    this.selectAll = allRowsOnPageSelected;

    this.checkButtonDisabled();
    this.getTooltip();
  }


  getTooltip(): string {
    const selectedRows = this.checkboxService.getSelectedRows();
    if (selectedRows.length <= 1) {
      this.tooltipMessage = 'Please select one or more rows to download.';
      return this.tooltipMessage;
    } else if (selectedRows.length >= 200) {
      this.tooltipMessage = 'You can only download up to 200 files at once.';
      return this.tooltipMessage;
    } else {
      this.tooltipMessage = '';
      return this.tooltipMessage; // Return an empty string if conditions are met
    }
  }

  showData(row: any) {
    this.displayedFilesService.showData(row).subscribe(
      (data) => {
        this.displayedFilesService.openDialog(data, row);
      },
      (error) => {
        this.displayedFilesService.openErrorDialogDisplay(row);
      }
    );
  }



  displaySelectedRows() {
    const selectedRows = this.checkboxService.getSelectedRows();
    if (selectedRows.length >= 1 && selectedRows.length <= 200) {
      this.checkboxService.setButtonDisabled(true); // Disable the button while processing

      const startDate = this.dataService.getStartDate();
      const endDate = this.dataService.getEndDate();

    

      this.exportSearchService
        .displaySelectedRowsAndNavigate(startDate, endDate, selectedRows)
        .subscribe(
          (response) => {
            this.checkboxService.setButtonDisabled(false); // Re-enable the button after processing
            // You can initiate the download here if needed
          },
          (error) => {
            console.error('Error:', error);
            this.checkboxService.setButtonDisabled(false); // Re-enable the button after processing, in case of error
          },
        );
    }
  }


}
