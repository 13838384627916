import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private webSocketSubject!: WebSocketSubject<any>;
  private pingInterval: any; // To store the interval for ping messages
  private isConnected: boolean = false;

  constructor() {
    this.connectWebSocket();
  }

  connectWebSocket() {
    this.webSocketSubject = webSocket(environment.websocketUrl);

    // Handle WebSocket close event to automatically reconnect
    this.webSocketSubject.subscribe(
      () => {
        // WebSocket connected successfully, start pinging the server
        this.isConnected = true;
        this.startPinging();
      },
      (error) => {
        console.error('WebSocket closed unexpectedly. Reconnecting...', error);
        this.isConnected = false;
        this.stopPinging(); // Stop pinging when disconnected

        // Attempt to reconnect after a delay
        setTimeout(
          () => this.connectWebSocket(),
          environment.websocketReconnectDelay,
        );
      },
    );
  }

  startPinging() {
    // Clear any previous intervals
    this.stopPinging();

    // Send a ping message every 15 seconds to keep the connection alive
    this.pingInterval = setInterval(() => {
      if (this.webSocketSubject) {
        this.webSocketSubject.next({ action: 'ping' });
      } else {
        console.log('Connection not stored or offline, stopping ping.');
        this.stopPinging();
      }
    }, 15000); // 15 seconds interval
  }

  stopPinging() {
    // Clear the ping interval
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  sendMessage(message: any) {
    if (this.webSocketSubject) {
      this.webSocketSubject.next(message);
    } else {
      console.error('WebSocket connection not established.');
    }
  }

  getMessages() {
    return this.webSocketSubject.asObservable();
  }

  disconnect() {
    if (this.webSocketSubject) {
      console.log('Disconnecting WebSocket.');
      this.webSocketSubject.complete(); // Closes the WebSocket connection
      this.stopPinging(); // Stop the pinging when disconnected
      this.isConnected = false;
    }
  }
}
